import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './features/store';
import UserContext from './UserContext';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserContext>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </UserContext>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
