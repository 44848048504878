import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AllAttendanceRequest,
  AttendanceOutRequestType,
  GetEmployeeStatusResponse,
  GetEmployeesAttendanceResponse,
  GetemployeeActivityResponse,
  GetRegularizeRequest,
  GetRegularizeResponse,
  PutRegularizeRequestStatus,
  MarkInAttendanceBodyProps,
  SingleAttendenceRespnse,
} from './types';

export const AUTH_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AUTH_URL
    : process.env.REACT_APP_PROD_AUTH_URL;

export const attendanceApi = createApi({
  reducerPath: 'attendanceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_URL,
    prepareHeaders: (headers: any) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: [
    'queryAllusersAttendance',
    'mutateRole',
    'userAttendanceById',
    'getCurrentUser',
    'allUsersById',
    'userEmployeeAactivityById',
    'userEmployeeStatus',
    'GetRegularizeRequest',
    'monthlyAttendance',
    'PutRegularizeRequestStatus',
  ],
  endpoints: (builder) => ({
    clockInAttendance: builder.mutation<any, MarkInAttendanceBodyProps>({
      query: (attendance) => ({
        url: `/clockInAttendance`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: attendance,
        credentials: 'include',
      }),
      invalidatesTags: ['getCurrentUser'],
    }),

    clockOutAttendance: builder.mutation<any, AttendanceOutRequestType>({
      query: (attendance) => ({
        url: `/clockOutAttendance`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: attendance,
        credentials: 'include',
      }),
      invalidatesTags: ['getCurrentUser'],
    }),

    getEmployeesAttendance: builder.query<
      GetEmployeesAttendanceResponse,
      AllAttendanceRequest
    >({
      query: (args) => ({
        url: '/getEmployeesAttendance',
        credentials: 'include',
        params: args,
      }),
      providesTags: ['queryAllusersAttendance'],
    }),

    getEmployeeAttendance: builder.query<
      SingleAttendenceRespnse,
      {
        _id: string;
        timeZone: string;
        page?: number | undefined;
        limit?: number | undefined;
      }
    >({
      query: ({ _id, ...args }) => ({
        url: `/getEmployeeAttendance/${_id}`,
        method: 'GET',
        params: args,
      }),
      providesTags: ['userAttendanceById'],
    }),
    getEmployeeAactivity: builder.query<
      any,
      {
        employeeId?: string;
        startDate: string;
        endDate: string;
        limit: number;
        page: number;
      }
    >({
      query: ({ employeeId, startDate, endDate, limit = 10, page = 1 }) => ({
        url: `/getemployeeactivity/${employeeId}`,
        method: 'GET',
        params: {
          startDate,
          endDate,
          limit,
          page,
        },
      }),
      providesTags: ['userEmployeeAactivityById'],
    }),
    getuserStatus: builder.query<GetEmployeeStatusResponse, {}>({
      query: () => ({
        url: `/getuserStatus`,
        method: 'GET',
      }),
      providesTags: ['userEmployeeStatus'],
    }),
    getRegularizeRequests: builder.query<
      GetRegularizeResponse,
      GetRegularizeRequest
    >({
      query: () => ({
        url: `/regularise`,
        method: 'GET',
      }),
      providesTags: ['GetRegularizeRequest'],
    }),
    putRegularizeRequestsStatus: builder.mutation<
      any,
      PutRegularizeRequestStatus
    >({
      query: (data) => ({
        url: '/regularise',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['PutRegularizeRequestStatus'],
    }),
    takeScreenShot: builder.mutation<
      GetemployeeActivityResponse,
      {
        attendanceId?: string;
      }
    >({
      query: (args) => ({
        url: `/data`,
        method: 'GET',
        params: args,
      }),
    }),
    getMonthlyAttendances: builder.query<
      any,
      { startDate: string; endDate: string; page: number; pageSize: number }
    >({
      query: ({ startDate, endDate, page, pageSize }) => ({
        url: `/getCurrentMonth`,
        method: 'GET',
        params: { startDate, endDate, page, pageSize },
      }),
      providesTags: ['monthlyAttendance'],
    }),
    getEmployeeMonthly: builder.query<
      any,
      { employeeId: string; startDate: string; endDate: string }
    >({
      query: ({ employeeId, startDate, endDate }) => ({
        url: `/getEmployeeMonthly/${employeeId}`,
        method: 'GET',
        params: { startDate, endDate },
      }),
      providesTags: (result, error, { employeeId }) =>
        result ? [{ type: 'monthlyAttendance', id: employeeId }] : [],
    }),
  }),
});
export const {
  useClockInAttendanceMutation,
  useClockOutAttendanceMutation,
  useGetEmployeeAttendanceQuery,
  useLazyGetEmployeesAttendanceQuery,
  useLazyGetEmployeeAactivityQuery,
  useGetuserStatusQuery,
  useLazyGetMonthlyAttendancesQuery,
  useTakeScreenShotMutation,
  useGetRegularizeRequestsQuery,
  useLazyGetEmployeeMonthlyQuery,
  usePutRegularizeRequestsStatusMutation,
} = attendanceApi;
