import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userLoginTypes } from '../../types/userTypes';
import { Doc } from 'prettier';
import { Permission } from '../../components/Admin/UpdatePermissions/RolesSection';

type personalInfoType = {
  id: string | undefined;
  name: string | undefined;
  mobile: string | undefined;
  emergency_contact: string | undefined;
  dob: Date | undefined;
  address: string | undefined;
  bank_name: string | undefined;
  bank_ifsc: string | undefined;
  account_number: string | undefined;
  account_holder_name: string | undefined;
};

type leaveData = {
  leave_data: {}[];
  role: string | undefined;
  name: string | undefined;
  email: string | undefined;
};
type deleteEmployee = {
  id: string;
};

type activateDeactiavateEmployee = {
  id: string;
};
type DocumentFieldUpdate = {
  id: string;
  type: string;
};

interface User {
  isProbationServed: any;
  _id: string;
  name: string;
  email: string;
  mobile: Number;
  emergency_contact: Number;
  dob: string;
  address: string;
  password: string;
  employment_type: string;
  verified: string;
  role: string;
  leave_data: [{ leave_type: string; leave_left: Number }];
  leave_dates: [];
  createdAt: string;
  assigned_HR: string;
  assigned_Manager: string;
}

interface Employees {
  _id: string;
  name: string;
  dob: string;
  slack_id: string;
  id: string;
  avatar: string;
}

interface RolesEmployees {
  _id: string;
  name: string;
  role: string;
  avatar: string;
}

interface Roles {
  _id: string;
  roleName: string;
  employees: RolesEmployees[];
}

const AUTH_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AUTH_URL
    : process.env.REACT_APP_PROD_AUTH_URL;

// const AUTH_URL = process.env.REACT_APP_PROD_AUTH_URL;

export const authApi = createApi({
  reducerPath: 'authApi',
  // keepUnusedDataFor: 3,
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_URL,
    prepareHeaders: (headers: any) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: [
    'queryAllusers',
    'queryRoles',
    'mutateRole',
    'userById',
    'getCurrentUser',
    'allUsersById',
  ],
  endpoints: (builder) => ({
    userSignup: builder.mutation<
      {
        message: string;
        status: boolean;
      },
      {
        email: string | undefined;
        employment_type: string | undefined;
        hr: string | undefined;
        attendancePassword: string | undefined;
        slack_id: string | undefined;
      }
    >({
      query: (userdata) => ({
        url: '/register',
        method: 'POST',
        body: userdata,
        headers: {
          'content-type': 'application/json',
        },
      }),
    }),
    userLogin: builder.mutation<any, userLoginTypes>({
      query: (userdata) => ({
        url: '/login',
        method: 'POST',
        body: userdata,
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
      }),
      invalidatesTags: ['getCurrentUser'],
    }),

    getRoles: builder.query<
      { status: string; log: string; roles: any[] },
      void
    >({
      query: () => ({
        url: '/roles',
        credentials: 'include',
      }),
      providesTags: ['queryRoles'],
    }),

    editRole: builder.mutation<
      any,
      { roleId: string; permissions: Permission[] }
    >({
      query: ({ roleId, permissions }) => ({
        url: `/roles/${roleId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { permissions },
      }),
    }),

    createRole: builder.mutation<
      any,
      { roleName: string; permissions: Permission[] }
    >({
      query: (newRole) => ({
        url: '/roles',
        method: 'POST',
        body: newRole,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),

    updateRole: builder.mutation({
      query: ({ employeeId, role }) => ({
        url: `/roles/${employeeId}`,
        method: 'PATCH',
        body: { role },
      }),
      invalidatesTags: ['allUsersById'],
    }),

    getAllusers: builder.query<
      { status: string; log: string; employee: any[] },
      void
    >({
      query: () => ({
        url: '/getEmployees',
        credentials: 'include',
      }),
      providesTags: ['queryAllusers'],
    }),
    getAllUsersById: builder.query({
      query: (ids) => ({
        url: '/employees/multiple-payslip',
        method: 'GET',
        body: ids,
        credentials: 'include',
      }),
      providesTags: ['allUsersById'],
    }),
    changeRole: builder.mutation<
      any,
      { email: string | undefined; role: string }
    >({
      query: (role) => ({
        url: '/updateRole',
        method: 'POST',
        body: role,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['queryAllusers', 'userById'],
    }),
    changeEmploymentType: builder.mutation<
      any,
      { email: string | undefined; employment_type: string }
    >({
      query: (employment_type) => ({
        url: '/updateEmploymentType',
        method: 'POST',
        body: employment_type,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['queryAllusers', 'userById'],
    }),
    getCurrentUser: builder.query<
      { status: string; log: string; User: User },
      void
    >({
      query: () => ({
        url: '/currentEmployee',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
      }),
      providesTags: ['getCurrentUser'],
    }),

    getVerifiedUsersBirthday: builder.query<
      { status: string; log: string; employees: Employees[] },
      void
    >({
      query: () => ({
        url: '/getVerifiedUsersBirthday',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
      }),
    }),
    getRolesEmployees: builder.query<
      { status: string; log: string; roles: Roles[] },
      void
    >({
      query: () => ({
        url: '/rolesEmployees',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
      }),
    }),
    getUserById: builder.query<any, { _id: string | undefined | null }>({
      query: ({ _id }) => ({
        url: `/employee/${_id}`,
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
      }),
      providesTags: ['userById'],
    }),

    changeManager: builder.mutation<
      any,
      { email: string | undefined; manager_id: string | undefined }
    >({
      query: ({ email, manager_id }) => ({
        url: '/assignManager',
        method: 'POST',
        body: { email, manager_id },
        credentials: 'include',
      }),
      invalidatesTags: ['queryAllusers', 'userById'],
    }),
    changeAdmin: builder.mutation<
      any,
      { email: string | undefined; admin_id: string | undefined }
    >({
      query: ({ email, admin_id }) => ({
        url: '/changeAdmin',
        method: 'POST',
        body: { email, admin_id },
        credentials: 'include',
      }),
      invalidatesTags: ['queryAllusers', 'userById'],
    }),
    chnagePassword: builder.mutation<
      any,
      {
        email: string | undefined;
        password: string | undefined;
        new_password: string | undefined;
      }
    >({
      query: ({ email, password, new_password }) => ({
        url: '/changePassword',
        method: 'POST',
        body: {
          email,
          password,
          newPassword: new_password,
        },
      }),
    }),
    forgotPassword: builder.mutation<
      any,
      {
        email: string | undefined;
      }
    >({
      query: ({ email }) => ({
        url: '/forgotpassword',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    resetPassword: builder.mutation<
      any,
      {
        token: string;
        newPassword: string | undefined;
      }
    >({
      query: ({ token, newPassword }) => ({
        url: `/reset-password/${token}`,
        method: 'POST',
        body: {
          newPassword,
        },
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: '/logout',
        method: 'DELETE',
        credentials: 'include',
      }),
      // invalidatesTags: ["getCurrentUser"],
    }),
    uploadDocuments: builder.mutation<any, { formData: FormData }>({
      query: ({ formData }) => ({
        url: '/uploadDocuments',
        method: 'POST',
        body: formData,
        credentials: 'include',
      }),
    }),
    updateDocuments: builder.mutation<any, { formData: FormData }>({
      query: ({ formData }) => ({
        url: '/uploadDocuments',
        method: 'PUT',
        body: formData,
        credentials: 'include',
      }),
    }),
    updateField: builder.mutation<any, { body: DocumentFieldUpdate }>({
      query: ({ body }) => ({
        url: '/uploadDocuments',
        method: 'PATCH',
        body,
        credentials: 'include',
      }),
    }),
    updatePersonalInfo: builder.mutation<any, { data: personalInfoType }>({
      query: ({ data }) => ({
        url: '/uploadPeronsalDetails',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
    }),
    verifyUser: builder.mutation<
      any,
      { email: string | undefined; status: string | undefined }
    >({
      query: ({ email, status }) => ({
        url: '/verify',
        method: 'POST',
        body: {
          email,
          status,
        },
      }),
      invalidatesTags: ['queryAllusers'],
    }),
    // not using this route
    downloadPdf: builder.query<void, { s3_url: string | undefined }>({
      query: ({ s3_url }) => ({
        url: `${AUTH_URL}/download/?id=${s3_url}`,
        credentials: 'include',
        method: 'GET',
        headers: {
          'content-type': 'application/pdf',
        },
      }),
    }),
    getDocuments: builder.query<{ list: Doc[] }, { userId: string }>({
      query: ({ userId }) => ({
        url: `${AUTH_URL}/documents/?userId=${userId}`,
        credentials: 'include',
        method: 'GET',
      }),
    }),
    changeLeave: builder.mutation<any, leaveData>({
      query: (leaveData) => ({
        url: '/changeLeaveData',
        method: 'POST',
        body: leaveData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['queryAllusers', 'getCurrentUser'],
    }),
    deleteEmployee: builder.mutation<any, deleteEmployee>({
      query: (employee) => ({
        url: `/delete/${employee.id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      invalidatesTags: ['queryAllusers', 'getCurrentUser'],
    }),
    updateSlackId: builder.mutation<
      any,
      { slack_id: string; employee_id: string }
    >({
      query: ({ slack_id, employee_id }) => ({
        url: '/updateSlackId',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { slack_id, employee_id },
      }),
    }),
    activateDeactiavateEmployee: builder.mutation<
      any,
      activateDeactiavateEmployee
    >({
      query: (employee) => ({
        url: `/activate-deactivate/${employee.id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      invalidatesTags: ['queryAllusers', 'getCurrentUser'],
    }),
  }),
});

export const {
  useUserLoginMutation,
  useUserSignupMutation,
  useGetRolesQuery,
  useEditRoleMutation,
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useGetAllusersQuery,
  useGetAllUsersByIdQuery,
  useGetVerifiedUsersBirthdayQuery,
  useGetRolesEmployeesQuery,
  useChangeRoleMutation,
  useChangeEmploymentTypeMutation,
  useGetUserByIdQuery,
  useUpdateSlackIdMutation,
  useChangeAdminMutation,
  useChangeManagerMutation,
  useChnagePasswordMutation,
  useGetCurrentUserQuery,
  useLogoutMutation,
  useUploadDocumentsMutation,
  useUpdatePersonalInfoMutation,
  useVerifyUserMutation,
  useDownloadPdfQuery,
  useChangeLeaveMutation,
  useDeleteEmployeeMutation,
  useGetDocumentsQuery,
  useUpdateDocumentsMutation,
  useUpdateFieldMutation,
  useActivateDeactiavateEmployeeMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
